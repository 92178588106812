//.loading-overlay {
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  -moz-transform: translateX(-50%) translateY(-50%);
//  -webkit-transform: translateX(-50%) translateY(-50%);
//  transform: translateX(-50%) translateY(-50%);
//}

/* Absolute Center Spinner */
.loading-overlay {
  position: fixed;
  z-index: 999;
  height: 100px; // Implies the spinner is always 100 px
  width: 100px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-overlay:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}