// Overrides for https://github.com/patientslikeme/react-calendar-heatmap

.react-calendar-heatmap .color-scale-0 { fill: blue; }
.react-calendar-heatmap .color-scale-1 { fill: purple; }
.react-calendar-heatmap .color-scale-2 { fill: orange; }
.react-calendar-heatmap .color-scale-3 { fill: red; }
.react-calendar-heatmap .color-scale-4 { fill: green; }


//.react-calendar-heatmap .color-gitlab-0 {
//  fill: #59bbd9;
//}
//.react-calendar-heatmap .color-gitlab-1 {
//  fill: #1ba2cc;
//}
//.react-calendar-heatmap .color-gitlab-2 {
//  fill: #147695;
//}
//.react-calendar-heatmap .color-gitlab-3 {
//  fill: #0d4a5d;
//}
//.react-calendar-heatmap .color-gitlab-4 {
//  fill: #051e26;
//}


//.react-calendar-heatmap .color-gitlab-0 {
//  fill: #ededed;
//}
//.react-calendar-heatmap .color-gitlab-1 {
//  fill: #acd5f2;
//}
//.react-calendar-heatmap .color-gitlab-2 {
//  fill: #7fa8d1;
//}
//.react-calendar-heatmap .color-gitlab-3 {
//  fill: #49729b;
//}
//.react-calendar-heatmap .color-gitlab-4 {
//  fill: #254e77;
//}

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ede9fc;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #cabcf5;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #8263e9;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #451dc9;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #261070;
}