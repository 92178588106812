/* ========================================================================
     Component: layout
 ========================================================================== */
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);
html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-size: 16px; }

html, body, #app {
  height: 100%; }

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden; }
  .wrapper.ng-leave {
    display: none; }
  .wrapper .aside-container {
    position: absolute;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 116;
    backface-visibility: hidden;
    background-color: #fff; }
    .wrapper .aside-container .aside-inner {
      padding-top: 55px;
      height: 100%;
      width: 220px;
      overflow: hidden; }
    .wrapper .aside-container .nav-floating {
      left: inherit;
      margin-left: 220px;
      z-index: 1110;
      min-width: 190px;
      overflow: auto; }
  .wrapper .section-container {
    position: relative;
    height: 100%;
    margin-left: 0;
    z-index: 111;
    background-color: #f5f7fa;
    margin-bottom: 60px !important; }
  .wrapper .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    border-top: 1px solid #e4eaec;
    padding: 20px;
    z-index: 109;
    font-size: .875rem; }
    .wrapper .footer-container p {
      margin: 0; }

.modal-open .wrapper {
  z-index: 0; }

.content-wrapper {
  padding: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px; }
  .content-wrapper .unwrap {
    margin: -15px; }
    @media (min-width: 768px) {
      .content-wrapper .unwrap {
        margin: -20px; } }
  .content-wrapper .content-heading {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.1;
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 1px solid #cfdbe2; }
    .content-wrapper .content-heading small {
      display: block;
      font-size: 12px;
      color: #909FA7; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding: 20px; }
      .content-wrapper .content-heading {
        margin: -20px;
        margin-bottom: 20px;
        padding: 20px; }
        .content-wrapper .content-heading button,
        .content-wrapper .content-heading .btn {
          margin: 0; } }

@media (min-width: 768px) {
  body {
    min-height: 100%; }
  .wrapper .section-container,
  .wrapper .footer-container {
    margin-left: 220px; }
  .wrapper .section-container.has-sidebar-right {
    margin-right: 240px; }
    .wrapper .section-container.has-sidebar-right + .offsidebar {
      z-index: 1; } }

@media (max-width: 767.98px) {
  .wrapper .aside-container {
    margin-left: -220px; }
  .aside-toggled .wrapper .section-container,
  .aside-toggled .wrapper .footer-container {
    margin-left: 220px; }
  .aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .wrapper .section-container,
    .csstransforms3d .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-220px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-toggled .wrapper .footer-container {
    transform: translate3d(220px, 0, 0); }
  .csstransforms3d .aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

@media (max-width: 767.98px) {
  .aside-collapsed .wrapper .aside-container {
    margin-left: -70px; }
  .aside-collapsed.aside-toggled .wrapper .section-container,
  .aside-collapsed.aside-toggled .wrapper .footer-container {
    margin-left: 70px; }
  .aside-collapsed.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed .wrapper .section-container,
    .csstransforms3d .aside-collapsed .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .aside-collapsed .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-70px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .footer-container {
    transform: translate3d(70px, 0, 0); }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

.aside-collapsed {
  overflow-y: auto; }
  .aside-collapsed .wrapper .aside-container,
  .aside-collapsed .wrapper .aside-container .aside-inner {
    width: 70px; }
  .aside-collapsed .wrapper .aside-container .nav-floating {
    margin-left: 70px; }

@media (min-width: 768px) {
  .aside-collapsed .wrapper .section-container,
  .aside-collapsed .wrapper .footer-container {
    margin-left: 70px; } }

@media (max-width: 767.98px) {
  .aside-collapsed-text .wrapper .aside-container {
    margin-left: -90px; }
  .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    margin-left: 90px; }
  .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed-text .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed-text .wrapper .section-container,
    .csstransforms3d .aside-collapsed-text .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .aside-collapsed-text .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-90px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    transform: translate3d(90px, 0, 0); }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

.aside-collapsed-text {
  overflow-y: auto; }
  .aside-collapsed-text .wrapper .aside-container,
  .aside-collapsed-text .wrapper .aside-container .aside-inner {
    width: 90px; }
  .aside-collapsed-text .wrapper .aside-container .nav-floating {
    margin-left: 90px; }

@media (min-width: 768px) {
  .aside-collapsed-text .wrapper .section-container,
  .aside-collapsed-text .wrapper .footer-container {
    margin-left: 90px; } }

@media (max-width: 1140px) {
  .layout-fixed.aside-toggled, .layout-fixed.offsidebar-open {
    overflow-y: hidden; } }

.layout-fixed .wrapper {
  /* only applied to sidebar */ }
  .layout-fixed .wrapper .topnavbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 121; }
  .layout-fixed .wrapper .aside-container,
  .layout-fixed .wrapper .offsidebar {
    position: fixed; }
  .layout-fixed .wrapper .aside-container {
    /* safari fix */
    height: 1px;
    min-height: 100%;
    /* ios blanks space fix */ }
    .layout-fixed .wrapper .aside-container .aside-inner {
      position: fixed;
      top: 0;
      bottom: 0; }
  .layout-fixed .wrapper .section-container {
    margin-top: 55px; }

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */
_:-ms-lang(x),
.layout-fixed .wrapper .aside-container .aside-inner {
  position: static !important; }

@media (min-width: 992px) {
  .layout-boxed {
    overflow: auto !important; }
    .layout-boxed .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
      .layout-boxed .wrapper .offsidebar {
        position: absolute !important; }
      .layout-boxed .wrapper .aside-container {
        left: inherit; }
      .layout-boxed .wrapper,
      .layout-boxed .wrapper .topnavbar-wrapper {
        width: 970px; }
    .layout-boxed.layout-fixed .wrapper .aside-container .aside-inner {
      left: inherit; } }

@media (min-width: 1200px) {
  .layout-boxed .wrapper,
  .layout-boxed .wrapper .topnavbar-wrapper {
    width: 1140px; } }

.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 220px;
  z-index: 112; }
  .aside-collapsed .sidebar-backdrop {
    left: 70px; }
  .aside-collapsed-text .sidebar-backdrop {
    left: 90px; }

/* ========================================================================
   Component: layout-extra
 ========================================================================== */
.hidden-footer .wrapper .footer-container {
  display: none; }

.hidden-footer .wrapper .section-container {
  margin-bottom: 0 !important; }

.layout-fs .wrapper .section-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  top: 55px;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow: hidden; }
  .layout-fs .wrapper .section-container .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 0; }
    .layout-fs .wrapper .section-container .content-wrapper > * {
      width: 100%;
      height: 100%; }

.layout-fs.hidden-footer .wrapper .section-container {
  bottom: 0; }

.layout-h .wrapper .section-container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .layout-h .wrapper .section-container .content-wrapper .content-heading {
    display: none; }

.layout-h .wrapper .section-container {
  margin-top: 0; }

.layout-h .wrapper .offsidebar {
  margin-top: 55px; }

.layout-h .wrapper .footer-container {
  margin: 0; }

.layout-h.layout-fixed .wrapper .section-container,
.layout-h.layout-fixed .wrapper .offsidebar {
  margin-top: 55px; }

.aside-float .wrapper {
  box-shadow: 0 0 0 #000; }
  .aside-float .wrapper .footer-container {
    border: 0; }
  .aside-float .wrapper .aside-container {
    padding: 15px 0;
    background: transparent; }
    .aside-float .wrapper .aside-container .aside-inner {
      height: 100%;
      top: 15px;
      bottom: 15px;
      left: inherit; }
    .aside-float .wrapper .aside-container .sidebar {
      border: 1px solid #e4eaec; }
    .aside-float .wrapper .aside-container .sidebar:after {
      display: none; }
  @media (min-width: 768px) {
    .aside-float .wrapper .section-container {
      padding-left: 15px; }
    .aside-float .wrapper .footer-container {
      left: 15px; } }
  .aside-float .wrapper .content-wrapper {
    border: 0; }
    .aside-float .wrapper .content-wrapper .content-heading {
      padding-top: 30px;
      border: 0;
      background-color: transparent; }
    .aside-float .wrapper .content-wrapper .unwrap {
      margin: 0 !important; }

.aside-float.aside-toggled .wrapper .aside-container {
  transition: delay(0.05s); }
  .aside-float.aside-toggled .wrapper .aside-container .aside-inner {
    margin-left: 15px; }

.aside-float.aside-toggled .wrapper .section-container {
  padding-left: 15px; }

.aside-float.aside-toggled .wrapper .footer-container {
  left: 15px; }

@media (min-width: 768px) {
  .aside-float.aside-collapsed .topnavbar .navbar-header {
    width: 100px; }
  .aside-float.aside-collapsed-text .topnavbar .navbar-header {
    width: 120px; }
  .aside-float.layout-fs .wrapper .section-container .content-wrapper {
    padding: 0 0 0 20px; }
  .aside-float .wrapper .aside-container .aside-inner {
    margin-left: 15px; } }

@media (min-width: 992px) {
  .aside-float.layout-boxed .wrapper .aside-container .aside-inner {
    margin-left: 0; }
  .aside-float.layout-boxed .wrapper .section-container {
    padding-left: 0; } }

.aside-float.aside-toggled.layout-fs .wrapper .section-container .content-wrapper {
  padding: 0 0 0 20px; }

/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */
.wrapper .aside-container {
  transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1); }
  @media screen and (prefers-reduced-motion: reduce) {
    .wrapper .aside-container {
      transition: none; } }

.aside-inner,
.navbar-header,
.sidebar > .sidebar-nav > li {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.wrapper .section-container {
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.sidebar > .sidebar-nav .badge {
  animation: fadeInRight 1s;
  animation-fill-mode: both; }

.aside-collapsed .sidebar > .sidebar-nav .badge,
.aside-collapsed-text .sidebar > .sidebar-nav .badge {
  animation: fadeIn 1s; }

.sidebar .sidebar-nav > li > a {
  animation: fadeInLeft .5s; }

.sidebar > .sidebar-nav > .nav-heading,
.sidebar > .sidebar-nav > li > a > span,
.navbar-brand .brand-logo {
  animation: fadeIn 1s; }

.sidebar li > a,
.sidebar li > .nav-item,
.sidebar > .sidebar-nav > .nav-heading {
  white-space: nowrap; }

.aside-collapsed .user-block-picture,
.aside-collapsed-text .user-block-picture {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: 55px;
  background-color: #23b7e5;
  background-image: linear-gradient(to right, #23b7e5 0%, #51c6ea 100%);
  background-repeat: repeat-x; }
  @media (min-width: 768px) {
    .topnavbar .navbar-header {
      width: 220px;
      text-align: center; }
      .topnavbar .navbar-header .navbar-brand {
        width: 100%; } }

.topnavbar {
  position: relative; }
  .topnavbar .navbar-header {
    background-color: transparent; }
  .topnavbar .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem; }
    @media (min-width: 768px) {
      .topnavbar .navbar-header {
        padding-left: 0; } }
    .topnavbar .navbar-header .navbar-brand {
      padding: 0; }
    .topnavbar .navbar-header .brand-logo > img,
    .topnavbar .navbar-header .brand-logo-collapsed > img {
      margin: 0 auto; }
    .topnavbar .navbar-header .brand-logo {
      display: block;
      padding: 10px 15px; }
    .topnavbar .navbar-header .brand-logo-collapsed {
      display: none;
      padding: 6px 15px; }
  .topnavbar .dropdown {
    position: static; }
    .topnavbar .dropdown .dropdown-menu {
      position: absolute;
      margin-top: 0;
      top: auto;
      left: 0;
      right: 0; }
  @media (min-width: 992px) {
    .topnavbar .dropdown {
      position: relative; }
      .topnavbar .dropdown .dropdown-menu {
        top: 54px;
        left: 0;
        right: auto; }
      .topnavbar .dropdown .dropdown-menu-right {
        right: 0;
        left: auto; } }
  .topnavbar .navbar-nav > .nav-item > .navbar-text {
    color: #fff; }
  .topnavbar .navbar-nav > .nav-item > .nav-link {
    padding: 1.1rem .95rem;
    font-size: .85rem; }
  .topnavbar .navbar-nav > .nav-item > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link {
    color: #fff; }
    .topnavbar .navbar-nav > .nav-item > .nav-link:hover, .topnavbar .navbar-nav > .nav-item > .nav-link:focus,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      color: #117391; }
  .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    background-color: transparent; }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 55px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    transition: color 0.3s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        transition: none; } }
    @media (min-width: 768px) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #fff; } }
  @media (max-width: 767.98px) {
    .topnavbar .navbar-text {
      margin: 10px; } }

.layout-h .topnavbar .navbar-header {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: left; }
  @media (min-width: 992px) {
    .layout-h .topnavbar .navbar-header {
      width: auto; } }
  .layout-h .topnavbar .navbar-header .navbar-brand {
    min-height: 55px; }

.layout-h .topnavbar .navbar-toggler {
  border: 0;
  margin-left: auto; }
  .layout-h .topnavbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

@media (max-width: 991.98px) {
  .layout-h .topnavbar .dropdown-menu {
    left: 0 !important;
    right: 0 !important; } }

.layout-h .topnavbar .navbar-form {
  left: 0; }

@media (max-width: 767.98px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; }
    .sidebar-toggle > em {
      color: white; } }

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 55px;
  z-index: 9001;
  transition: all .3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3; }
  .topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%; }
  .topnavbar .navbar-form .form-control {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%; }
  .topnavbar .navbar-form.open {
    top: 0; }
  .topnavbar .navbar-form .navbar-form-close {
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 50%;
    right: 0;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto; }

@media (min-width: 576px) {
  .topnavbar .navbar-form {
    left: 220px; } }

@media (min-width: 992px) {
  .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    box-shadow: 0 -3px 0 #19a5d1 inset;
    transition: all 0.2s; } }
  @media screen and (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      transition: none; } }

@media (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px; }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px; } }

@media (min-width: 768px) {
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed-text .topnavbar .navbar-header {
    width: 90px; }
  .aside-collapsed-text .topnavbar .navbar-form {
    left: 90px; } }

/* ========================================================================
     Component: sidebar
 ========================================================================== */
.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .sidebar:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px); }
  .sidebar .nav-heading {
    padding: 12px 15px;
    color: #919DA8;
    font-size: 13px;
    letter-spacing: .035em;
    pointer-events: none;
    cursor: default; }

.sidebar-nav {
  position: relative;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .sidebar-nav > .nav-heading:first-child {
    padding-top: 20px; }
  .sidebar-nav > li {
    display: block;
    border-left: 3px solid transparent;
    transition: border-left-color 0.4s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .sidebar-nav > li {
        transition: none; } }
    .sidebar-nav > li > a,
    .sidebar-nav > li > .nav-item {
      position: relative;
      display: block;
      padding: 12px 24px;
      color: #515253;
      letter-spacing: .025em;
      font-weight: normal;
      cursor: pointer; }
      .sidebar-nav > li > a:focus, .sidebar-nav > li > a:hover,
      .sidebar-nav > li > .nav-item:focus,
      .sidebar-nav > li > .nav-item:hover {
        text-decoration: none;
        outline: none;
        color: #23b7e5; }
      .sidebar-nav > li > a > em,
      .sidebar-nav > li > .nav-item > em {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: inherits; }
    .sidebar-nav > li.active,
    .sidebar-nav > li.active > a,
    .sidebar-nav > li.active > .nav-item,
    .sidebar-nav > li.active .sidebar-nav, .sidebar-nav > li.open,
    .sidebar-nav > li.open > a,
    .sidebar-nav > li.open > .nav-item,
    .sidebar-nav > li.open .sidebar-nav {
      background-color: #fcfcfc;
      color: #23b7e5; }
    .sidebar-nav > li.active > .nav-item > em,
    .sidebar-nav > li.active > a > em, .sidebar-nav > li.open > .nav-item > em,
    .sidebar-nav > li.open > a > em {
      color: #23b7e5; }
    .sidebar-nav > li.active {
      border-left-color: #23b7e5; }
  .sidebar-nav .badge {
    display: block; }

@media only screen and (min-width: 1025px) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -17px;
    overflow-y: scroll; } }

.sidebar-subnav {
  background-color: #fff; }
  .sidebar-subnav > .sidebar-subnav-header {
    color: #515253;
    display: none;
    padding: 10px 20px;
    font-weight: bold; }
  .sidebar-subnav > li {
    border-left: 0 !important; }
    .sidebar-subnav > li > a,
    .sidebar-subnav > li > .nav-item {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 53px;
      font-weight: normal;
      background-color: transparent !important;
      color: #515253; }
      .sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover,
      .sidebar-subnav > li > .nav-item:focus,
      .sidebar-subnav > li > .nav-item:hover {
        color: #23b7e5; }
      .sidebar-subnav > li > a > em,
      .sidebar-subnav > li > .nav-item > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em; }
    .sidebar-subnav > li.active > a,
    .sidebar-subnav > li.active > .nav-item {
      color: #23b7e5; }
      .sidebar-subnav > li.active > a:after,
      .sidebar-subnav > li.active > .nav-item:after {
        border-color: #23b7e5;
        background-color: #23b7e5; }
    .sidebar-subnav > li .sidebar-nav > li {
      padding-left: 5px; }
  .sidebar-subnav.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; }
    .sidebar-subnav.nav-floating,
    .sidebar-subnav.nav-floating .collapse, .sidebar-subnav.nav-floating .sidebar-subnav {
      height: auto !important;
      display: block !important;
      visibility: visible !important; }
      .sidebar-subnav.nav-floating.opening,
      .sidebar-subnav.nav-floating .collapse.opening, .sidebar-subnav.nav-floating .sidebar-subnav.opening {
        animation: none !important; }
    .sidebar-subnav.nav-floating > .sidebar-subnav-header {
      display: block; }
    .sidebar-subnav.nav-floating li > a,
    .sidebar-subnav.nav-floating li > .nav-item {
      padding-left: 20px; }
      .sidebar-subnav.nav-floating li > a em,
      .sidebar-subnav.nav-floating li > .nav-item em {
        margin-left: 0; }

@media (min-width: 768px) {
  .sidebar > .sidebar-nav .badge {
    margin: 2px 0 0 0; } }

.aside-collapsed .sidebar,
.aside-collapsed-text .sidebar {
  overflow-x: hidden; }
  .aside-collapsed .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed .sidebar > .sidebar-nav > li > a > span,
  .aside-collapsed-text .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed-text .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
    display: none !important; }
  .aside-collapsed .sidebar > .sidebar-nav > li,
  .aside-collapsed-text .sidebar > .sidebar-nav > li {
    width: 69px; }
    .aside-collapsed .sidebar > .sidebar-nav > li > a,
    .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item {
      text-indent: -3px;
      padding: 20px 0;
      text-align: center; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > em {
        font-size: 1.6em;
        width: auto; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus {
        background-color: transparent; }
        .aside-collapsed .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus > em {
          color: inherit; }
  .aside-collapsed .sidebar .sidebar-nav .badge,
  .aside-collapsed-text .sidebar .sidebar-nav .badge {
    position: absolute;
    top: 10px;
    right: 5px;
    text-indent: 0; }

.aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
.aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
  display: block !important;
  font-size: 12px; }

.aside-collapsed-text .sidebar > .sidebar-nav > li {
  width: 88px; }
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a {
    padding: 14px 0; }
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em {
      font-size: 1.4em; }

/* ========================================================================
     Component: offsidebar
 ========================================================================== */
.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 235px;
  margin-top: 55px;
  border-left: 1px solid #cccccc;
  background-color: #fff;
  color: #656565;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  .offsidebar > .list-group {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px); }
  .offsidebar .progress {
    border: 0; }
  .offsidebar .tab-content {
    padding: 0;
    border: 0; }
  .offsidebar .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .offsidebar .nav-tabs > .nav-item > .nav-link {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 0;
      color: #909FA7; }
      .offsidebar .nav-tabs > .nav-item > .nav-link.active {
        color: #1174ba; }
  @media (min-width: 768px) {
    .offsidebar {
      margin-top: 55px; } }

.offsidebar-open .offsidebar {
  overflow-y: auto;
  z-index: 116; }

@media (min-width: 768px) {
  .offsidebar-open {
    overflow-y: auto; } }

.offsidebar {
  right: -235px; }

.no-csstransforms3d .offsidebar-open .offsidebar {
  right: 0; }

/* Transformation ready devices*/
.csstransforms3d .offsidebar {
  right: 0;
  transform: translate3d(235px, 0, 0);
  transition: transform .3s ease, z-index 0s linear .3s; }

.csstransforms3d .offsidebar-open .offsidebar {
  transform: translate3d(0, 0, 0);
  transition: transform .3s ease; }

/* ========================================================================
     Component: user-block
 ========================================================================== */
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important; }

.user-block {
  position: relative;
  padding: 25px 0 10px;
  cursor: pointer; }
  .user-block::after {
    display: block;
    clear: both;
    content: ""; }
  .user-block > .user-block-picture {
    position: relative;
    width: 60px;
    margin: 0 auto; }
    .user-block > .user-block-picture > img {
      max-width: 100%;
      height: auto; }
  .user-block .user-block-info {
    padding-top: 15px;
    text-align: center;
    white-space: nowrap; }
    .user-block .user-block-info .user-block-name, .user-block .user-block-info .user-block-role {
      display: block; }
    .user-block .user-block-info .user-block-name {
      color: #7D848F; }
    .user-block .user-block-info .user-block-role {
      font-size: 12px;
      color: #aaa; }

.user-block-status {
  position: relative; }
  .user-block-status > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff; }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  padding: 15px 0 14px;
  margin: 0;
  text-align: center; }
  .aside-collapsed .user-block > .user-block-picture, .aside-collapsed-text .user-block > .user-block-picture {
    float: none;
    margin: 0  auto;
    width: 50px; }
    .aside-collapsed .user-block > .user-block-picture > .user-block-status, .aside-collapsed-text .user-block > .user-block-picture > .user-block-status {
      display: block; }
  .aside-collapsed .user-block .user-block-info, .aside-collapsed-text .user-block .user-block-info {
    display: none; }

.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split::after {
        display: block;
        clear: both;
        content: ""; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0; }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .icon-check {
        opacity: 1 !important; }

/* ========================================================================
     Component: typo
 ========================================================================== */
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #656565;
  font-size: 0.875rem; }

h1, h2, h3, h4 {
  font-weight: bold; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.65625rem; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.875rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: .875rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-bottom: .65rem; }

/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */
*:not(.react-grid-Cell):focus {
  outline: 0 !important; }

a {
  outline: none !important; }
  a.text-muted:hover, a.text-muted:focus {
    color: #748690; }

hr {
  border-top: 1px solid #e4eaec; }

[data-now] {
  display: inline-block; }

.badge {
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem;
  border-radius: 0.625rem; }
  .badge.badge-warning {
    color: #fff !important; }
  .badge.badge-secondary {
    background-color: #3a3f51;
    color: #fff; }

.list-group {
  line-height: 1.3; }
  .list-group .list-group-item {
    padding: 10px;
    color: #555; }
    .list-group .list-group-item.active {
      color: #fff; }
      .list-group .list-group-item.active .badge {
        background-color: #fff;
        color: #1174ba; }
    .card > .list-group .list-group-item {
      border: 0; }

.list-group + .card-footer {
  border-top: 0; }

.page-header {
  padding-bottom: 0.59375rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid #e4eaec; }

.card {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .card .card-header {
    border-bottom: 0;
    padding: 0.625rem 0.9375rem;
    background-color: transparent; }
    .card .card-header .card-title {
      margin-bottom: 0;
      font-size: 1rem; }
    .card .card-header a {
      text-decoration: none !important; }

.card-body {
  padding: 0.9375rem; }

.card-footer {
  padding: 0.625rem 0.9375rem; }

.card-default {
  border-top-width: 3px;
  border-color: #cfdbe2; }
  .card-default .card-header {
    background-color: #fff; }

.well {
  border: 1px solid #dde6e9; }

.jumbotron {
  border: 1px solid #dde6e9;
  background-color: #fff; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 2rem 1rem; } }

.nav-tabs > .nav-item > .nav-link {
  font-weight: bold;
  color: #656565;
  background-color: #edf1f2;
  margin: 0;
  border: 1px solid #dde6e9;
  border-radius: 0;
  padding: 10px 20px; }
  .nav-tabs > .nav-item > .nav-link.active {
    background-color: #fff; }
    .nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
      border-bottom-color: #fff; }

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #dde6e9; }
  .nav-pills + .tab-content {
    border: 0;
    padding: 0; }
  .p-0 .tab-content {
    padding: 0 !important; }

.btn {
  border-radius: 3px;
  font-size: 13px;
  -webkit-appearance: none;
  outline: none !important;
  transition: all 0.1s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn.btn-link {
    box-shadow: none;
    border: 0; }
  .input-group .btn {
    font-size: 14px;
    border-color: #dde6e9; }
  .input-group .form-control-sm + .input-group-btn .btn {
    font-size: 0.8125rem;
    padding: 0.3125rem 0.625rem;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.btn.btn-warning {
  color: #fff; }

.btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus, .btn.btn-outline-warning:active {
  color: #fff; }

.btn-secondary {
  background-color: #fff;
  border-color: #eaeaea;
  color: #333; }
  .btn-secondary:hover {
    background-color: #f5f5f5; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #f5f5f5;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-outline-secondary {
  border-color: #eaeaea !important;
  color: #333; }
  .btn-outline-secondary:hover {
    color: #333;
    background-color: #f5f5f5 !important; }
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5); }

span.btn {
  -webkit-appearance: none !important; }

.form-control {
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem; }
  .form-control:focus {
    border-color: #66afe9; }

.form-control-sm,
select.form-control-sm {
  font-size: .75rem;
  height: 31px; }

.custom-select {
  -webkit-appearance: none; }

.custom-control-label::before {
  top: 0.19rem;
  border: 1px solid #dde6e9; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

.input-group-text {
  font-size: .875rem; }

@media (max-width: 767.98px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none; } }

.table-responsive {
  overflow-y: hidden; }

.table > thead > tr > th {
  border-bottom-width: 0; }

.table > tbody + tbody {
  border-bottom-width: 1px; }

.table .form-control {
  max-width: 100%; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px; }

.progress {
  border-radius: 0.2rem;
  border: 1px solid #e9ecef;
  background-color: #fff; }

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e4eaec;
  border-radius: 0.2rem; }
  .popover .popover-title {
    border: 0; }

.nav.nav-pills .active > a {
  background-color: #1174ba; }

.dropdown-menu {
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.3125rem 0;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  border-color: #e1e1e1; }
  .dropdown-menu .dropdown-item {
    line-height: 1.52857143;
    padding: 0.1875rem 1.25rem; }

.dropdown-header {
  color: #a1a2a3; }

.navbar-top .navbar-nav > .active > a {
  color: #999; }
  .navbar-top .navbar-nav > .active > a:hover, .navbar-top .navbar-nav > .active > a:focus {
    color: #d1d2d3; }

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.alert-primary {
  color: #fff;
  background-color: #1174ba;
  border-color: #1174ba; }
  .alert-primary hr {
    border-top-color: #0f65a3; }
  .alert-primary .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #fff;
  background-color: #fff;
  border-color: #fff; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }

.alert-success {
  color: #fff;
  background-color: #27c24c;
  border-color: #27c24c; }
  .alert-success hr {
    border-top-color: #23ad44; }
  .alert-success .alert-link {
    color: #e6e6e6; }

.alert-info {
  color: #fff;
  background-color: #23b7e5;
  border-color: #23b7e5; }
  .alert-info hr {
    border-top-color: #19a9d5; }
  .alert-info .alert-link {
    color: #e6e6e6; }

.alert-warning {
  color: #fff;
  background-color: #ff902b;
  border-color: #ff902b; }
  .alert-warning hr {
    border-top-color: #ff8312; }
  .alert-warning .alert-link {
    color: #e6e6e6; }

.alert-danger {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050; }
  .alert-danger hr {
    border-top-color: #ee3939; }
  .alert-danger .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #656565;
  border-color: #e4eaec; }

.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important; }

/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */
.container-sm {
  max-width: 540px;
  width: auto; }

.container-md {
  max-width: 720px;
  width: auto; }

.container-lg {
  max-width: 960px;
  width: auto; }

.row-flush {
  margin: 0; }
  .row-flush > .col,
  .row-flush > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #909FA7;
  padding: 10px 20px; }

.content-heading .breadcrumb {
  font-size: 0.8125rem;
  margin-bottom: 0; }

.content-heading + .breadcrumb {
  margin: -25px -25px 20px -20px;
  background-color: #fafbfc;
  border-top: 1px solid #cfdbe2;
  border-bottom: 1px solid #cfdbe2; }

.progress-sm {
  height: 15px; }

.progress-xs {
  height: 8px; }

.badge-inverse {
  color: #fff;
  background-color: #131e26; }
  .badge-inverse[href]:hover, .badge-inverse[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #020304; }

.badge-green {
  color: #fff;
  background-color: #37bc9b; }
  .badge-green[href]:hover, .badge-green[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2b957a; }

.badge-pink {
  color: #fff;
  background-color: #f532e5; }
  .badge-pink[href]:hover, .badge-pink[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #e90bd6; }

.badge-purple {
  color: #fff;
  background-color: #6d48e5; }
  .badge-purple[href]:hover, .badge-purple[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #4b1fdb; }

.alert-purple {
  color: #fff;
  background-color: #6d48e5;
  border-color: #6d48e5; }
  .alert-purple hr {
    border-top-color: #5b32e2; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .alert-green hr {
    border-top-color: #31a88b; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .alert-pink hr {
    border-top-color: #f41ae2; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .alert-inverse hr {
    border-top-color: #0b1115; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.form-control-rounded {
  border-radius: 100px; }

/* ========================================================================
     Component: button-extra
 ========================================================================== */
.btn-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #060a0d;
    border-color: #020304; }
  .btn-inverse:focus, .btn-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #020304;
    border-color: black; }
    .btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.btn-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .btn-green:hover {
    color: #fff;
    background-color: #2e9e83;
    border-color: #2b957a; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #2b957a;
    border-color: #298b72; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #6d48e5;
  border-color: #6d48e5; }
  .btn-purple:hover {
    color: #fff;
    background-color: #5227e0;
    border-color: #4b1fdb; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 72, 229, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #6d48e5;
    border-color: #6d48e5; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #4b1fdb;
    border-color: #481ed0; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 72, 229, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .btn-pink:hover {
    color: #fff;
    background-color: #f30ee0;
    border-color: #e90bd6; }
  .btn-pink:focus, .btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #e90bd6;
    border-color: #dc0bcb; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000; }

.btn-xs {
  padding: 0.0625rem 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.5; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 0.375rem 1rem;
  left: -1rem;
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-label.btn-label-right {
    left: auto;
    right: -1rem;
    border-radius: 0 0.25rem 0.25rem 0; }

.btn-lg .btn-label {
  padding: 0.625rem 1.25rem;
  left: -1.25rem;
  border-radius: 0.375rem 0 0 0.375rem; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -1.25rem;
    border-radius: 0 0.375rem 0.375rem 0; }

.btn-sm .btn-label {
  padding: 0.3125rem 0.625rem;
  left: -0.625rem;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-sm .btn-label.btn-label-right {
    left: auto;
    right: -0.625rem;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-xs .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  border-radius: 500px;
  padding: 0;
  border: 0; }
  .btn-circle > * {
    line-height: inherit !important; }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px; }

.btn-outline-inverse {
  color: #131e26;
  background-color: transparent;
  background-image: none;
  border-color: #131e26; }
  .btn-outline-inverse:hover {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-outline-inverse:focus, .btn-outline-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }
  .btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
    color: #131e26;
    background-color: transparent; }
  .btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inverse.dropdown-toggle {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
    .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, .btn-outline-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.btn-outline-green {
  color: #37bc9b;
  background-color: transparent;
  background-image: none;
  border-color: #37bc9b; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #37bc9b;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.btn-outline-purple {
  color: #6d48e5;
  background-color: transparent;
  background-image: none;
  border-color: #6d48e5; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #6d48e5;
    border-color: #6d48e5; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 72, 229, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #6d48e5;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #6d48e5;
    border-color: #6d48e5; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 72, 229, 0.5); }

.btn-outline-pink {
  color: #f532e5;
  background-color: transparent;
  background-image: none;
  border-color: #f532e5; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #f532e5;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

/* ========================================================================
     Component: placeholder
 ========================================================================== */
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444; }

.box-placeholder > :last-child {
  margin-bottom: 0; }

.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px; }

/* ========================================================================
     Component: cards
 ========================================================================== */
.card {
  margin-bottom: 1.25rem; }

.card .table {
  margin-bottom: 0; }
  .card .table > thead > tr > th {
    border-top: 0; }

.card.card-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .card.card-transparent .card-header,
  .card.card-transparent .card-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.card-flat {
  margin: 0 !important;
  border: 0; }

.card-columns-2 {
  column-count: 1; }
  @media (min-width: 768px) {
    .card-columns-2 {
      column-count: 2; } }

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center; }
  .card-tool:hover {
    cursor: pointer; }
  .card-tool > em + em {
    margin-left: 12px; }

.card-default .card-header > .card-tool {
  color: #c1c2c3; }

.card-header > .badge.float-right {
  margin-top: 3px; }
  .card-header > .badge.float-right + .badge.float-right {
    margin-right: 10px; }

.card-footer .pagination {
  margin: 0; }

.card-footer .radial-bar {
  margin-bottom: 0; }

.card-footer p {
  margin-bottom: 0; }

/* ========================================================================
     Component: circles
 ========================================================================== */
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 500px;
  margin: 0 .5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent; }
  .circle.text-left {
    margin-left: 0; }
  .circle.text-right {
    margin-right: 0; }

.circle-sm {
  width: 5px;
  height: 5px; }

.circle-lg {
  width: 11px;
  height: 11px; }

.circle-xl {
  width: 18px;
  height: 18px; }

/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */
.dropdown-list .dropdown-item {
  padding: 0; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .badge {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 2px 5px; }

.dropdown-menu-right-forced {
  right: 0 !important;
  left: auto !important; }

.dropdown-toggle-nocaret:after {
  display: none; }

/* ========================================================================
     Component: half-float
 ========================================================================== */
.half-float {
  position: relative;
  margin-bottom: 69px; }
  .half-float .half-float-bottom,
  .half-float .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -64px;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    z-index: 2; }
  .half-float .half-float-top {
    bottom: auto;
    top: -64px; }

/* ========================================================================
     Component: animate
 ========================================================================== */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    -ms-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.rag-fadeIn-enter {
  animation: fadeIn 0.5s; }

.rag-fadeIn-exit {
  display: none; }

.rag-fadeInRight-enter {
  animation: fadeInRight 0.5s; }

.rag-fadeInRight-exit {
  display: none; }

.rag-fadeInLeft-enter {
  animation: fadeInLeft 0.5s; }

.rag-fadeInLeft-exit {
  display: none; }

/* ========================================================================
     Component: slim-scroll
 ========================================================================== */
[data-scrollable] {
  display: block; }

.slimScrollBar {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 0 !important;
  border-radius: 1px !important; }

.slimScrollRail {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0; }

/* ========================================================================
     Component: inputs
 ========================================================================== */
/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox *,
  .c-radio * {
    cursor: pointer; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 1px; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #1174ba; }
  .form-inline .c-checkbox span, .form-inline
  .c-radio span {
    margin-left: 0; }
  .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
  .c-radio.c-checkbox-rounded span,
  .c-radio.c-radio-rounded span {
    border-radius: 500px; }

/* override for radio */
.c-radio span {
  border-radius: 500px; }

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-checkbox input[type=checkbox]:checked + span:before,
  .c-checkbox input[type=radio]:checked + span:before,
  .c-radio input[type=checkbox]:checked + span:before,
  .c-radio input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-checkbox input[type=checkbox]:checked + span,
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=checkbox]:checked + span,
  .c-radio input[type=radio]:checked + span {
    border-color: #1174ba;
    background-color: #1174ba; }
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=radio]:checked + span {
    background-color: #fff; }
    .c-checkbox input[type=radio]:checked + span:before,
    .c-radio input[type=radio]:checked + span:before {
      color: #1174ba; }
  .c-checkbox input[type=checkbox]:disabled + span,
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=checkbox]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-checkbox input[type=radio]:disabled + span:before,
    .c-radio input[type=radio]:disabled + span:before {
      color: #ddd; }

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    border-color: #1174ba;
    background-color: #1174ba; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    background-color: #fff; }
    .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
      background-color: #1174ba; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
      background-color: #ddd; }

.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  display: flex;
  align-items: center;
  margin: 0; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 100px;
    transition: all .5s;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #ddd;
    border-radius: 400px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .2s; }
  .switch.switch-lg span {
    width: 50px;
    height: 25px; }
  .switch.switch-lg span:after {
    height: 23px;
    width: 23px; }
  .switch.switch-sm span {
    width: 30px;
    height: 15px; }
  .switch.switch-sm span:after {
    height: 13px;
    width: 13px; }

.switch input:checked + span {
  background-color: #1174ba;
  border-color: #1174ba;
  transition: all .5s; }

.switch input:checked + span:after {
  left: 50%;
  transition: all .2s; }

.switch input:disabled + span {
  background-color: #f1f1f1;
  cursor: not-allowed; }

.note-editor {
  background-image: -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
  background-image: linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: #fff;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none; }
  .note-editor.note-editor-margin {
    background-image: -webkit-linear-gradient(0deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
    background-image: linear-gradient(90deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), linear-gradient(#eee 0.1em, transparent 0.1em);
    padding-left: 55px; }

/* ========================================================================
         Component: utils
 ========================================================================== */
.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.bl, .b, .ba {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.bt, .b, .ba {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.text-primary {
  color: #1174ba !important; }

.text-success {
  color: #27c24c !important; }

.text-info {
  color: #23b7e5 !important; }

.text-warning {
  color: #ff902b !important; }

.text-danger {
  color: #f05050 !important; }

.text-white {
  color: #fff !important; }

.text-inverse {
  color: #131e26 !important; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink {
  color: #f532e5 !important; }

.text-purple {
  color: #6d48e5 !important; }

.text-dark {
  color: #3a3f51 !important; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-green {
  color: #37bc9b !important; }

.text-yellow {
  color: #fad732 !important; }

.text-gray-darker {
  color: #232735 !important; }

.text-gray-dark {
  color: #3a3f51 !important; }

.text-gray {
  color: #dde6e9 !important; }

.text-gray-light {
  color: #e4eaec !important; }

.text-gray-lighter {
  color: #edf1f2 !important; }

.text-inherit {
  color: inherit !important; }

.text-sm {
  font-size: 0.74375rem; }

.text-md {
  font-size: 1.6625rem; }

.text-lg {
  font-size: 2.625rem; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #1174ba;
  color: #fff !important; }

.bg-primary-light {
  background-color: #1591e9;
  color: #fff !important; }

.bg-primary-dark {
  background-color: #0d578b;
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #27c24c;
  color: #fff !important; }

.bg-success-light {
  background-color: #43d967;
  color: #fff !important; }

.bg-success-dark {
  background-color: #1e983b;
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #23b7e5;
  color: #fff !important; }

.bg-info-light {
  background-color: #51c6ea;
  color: #fff !important; }

.bg-info-dark {
  background-color: #1797be;
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #ff902b;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ffab5e;
  color: #fff !important; }

.bg-warning-dark {
  background-color: #f77600;
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #f05050;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important; }

.bg-danger-dark {
  background-color: #ec2121;
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #37bc9b;
  color: #fff !important; }

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important; }

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #f532e5;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important; }

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #6d48e5;
  color: #fff !important; }

.bg-purple-light {
  background-color: #9175eb;
  color: #fff !important; }

.bg-purple-dark {
  background-color: #4b1fdb;
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #131e26;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important; }

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #fad732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #232735;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #3a3f51;
  color: #fff !important; }

.bg-gray {
  background-color: #dde6e9;
  color: #656565 !important; }

.bg-gray-light {
  background-color: #e4eaec;
  color: #656565 !important; }

.bg-gray-lighter {
  background-color: #edf1f2;
  color: #656565 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-cover {
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.list-icon em {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  line-height: 30px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .list-icon em {
      transition: none; } }

.list-icon div:hover em {
  transform: scale(3, 3); }

.d-table-fixed {
  table-layout: fixed; }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.clickable {
  cursor: pointer; }

.abs-center-container {
  position: relative; }

.abs-center {
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .abs-center.abs-fixed {
    position: fixed;
    z-index: 999; }
  .abs-center.abs-right {
    left: auto;
    right: 20px;
    text-align: right; }
  .abs-center.abs-left {
    right: auto;
    left: 20px;
    text-align: left; }

@media (max-height: 720px) {
  .abs-center {
    position: relative; } }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.ie-fix-flex {
  -ms-flex: 0 0 auto; }

/* ========================================================================
     Component: print
 ========================================================================== */
@media print {
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important; }
  .wrapper,
  .wrapper .section-container,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important; }
  .content-wrapper {
    overflow: hidden !important; } }

/* ========================================================================
     Component: chart-flot
 ======================================================================== */
.flot-chart {
  display: block;
  width: 100%;
  height: 250px; }
  .flot-chart .legend > table tr td {
    padding: 3px; }
  .flot-chart .legend > table tr td:first-child {
    padding-left: 3px; }
  .flot-chart .legend > table tr td:last-child {
    padding-right: 3px; }
  .flot-chart .legend > table tr + tr td {
    padding-top: 0; }
  .flot-chart .legend > div:first-child {
    border-color: rgba(0, 0, 0, 0.1) !important; }
  .flot-chart .legend .legendColorBox > div,
  .flot-chart .legend .legendColorBox > div > div {
    border-radius: 400px; }

.flot-chart-content {
  width: 100%;
  height: 100%; }

.flot-pie-label {
  padding: 3px 5px;
  font-size: 10px;
  text-align: center;
  color: #fff; }

#flotTip {
  position: relative;
  padding: 5px;
  font-size: 12px !important;
  border-radius: 2px !important;
  border-color: transparent !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  color: #f1f1f1;
  z-index: 5; }

/* ========================================================================
     Component: vector-map
 ========================================================================== */
body .jvectormap-label {
  position: absolute;
  display: none;
  border: solid 1px #313232;
  border-radius: 2px;
  background: #313232;
  color: white;
  padding: 3px 6px;
  opacity: 0.9;
  z-index: 1100; }

body .jvectormap-zoomin, body .jvectormap-zoomout {
  position: absolute;
  left: 10px;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background: #515253;
  padding: 5px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

body .jvectormap-zoomin {
  top: 10px; }

body .jvectormap-zoomout {
  top: 30px; }

/* Absolute Center Spinner */
.loading-overlay {
  position: fixed;
  z-index: 999;
  height: 100px;
  width: 100px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

/* Transparent Overlay */
.loading-overlay:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

.react-calendar-heatmap .color-scale-0 {
  fill: blue; }

.react-calendar-heatmap .color-scale-1 {
  fill: purple; }

.react-calendar-heatmap .color-scale-2 {
  fill: orange; }

.react-calendar-heatmap .color-scale-3 {
  fill: red; }

.react-calendar-heatmap .color-scale-4 {
  fill: green; }

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ede9fc; }

.react-calendar-heatmap .color-gitlab-1 {
  fill: #cabcf5; }

.react-calendar-heatmap .color-gitlab-2 {
  fill: #8263e9; }

.react-calendar-heatmap .color-gitlab-3 {
  fill: #451dc9; }

.react-calendar-heatmap .color-gitlab-4 {
  fill: #261070; }

.react-vis-magic-css-import-rule {
  display: inherit; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }

.rv-xy-plot canvas {
  pointer-events: none; }

.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series, .rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }

.rv-discrete-color-legend.horizontal {
  white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }

.rv-discrete-color-legend-item.horizontal {
  display: inline-block; }

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }

.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }

.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }

.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%; }

.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom; }

.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left, .rv-legend-titles__right, .rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }
